import type { GetStaticProps } from "next";
import { ContentPage } from "@/types/PropTypes";
import HomeContent from "@/components/HomeContent";
import BaseHeader from "@/components/BaseHeader";
import languages from "@/constants/languages.json";
import { getContentByPath, getContentTranslations } from "@/api/contentApis";

const Home = ({ metaTitle, metaDescription, metaKeywords, content }: ContentPage) => {
  return (
    <div>
      <BaseHeader title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <HomeContent content={content} />
    </div>
  );
};

export async function getStaticPaths() {
  const paths = languages.map((language: string) => ({
    params: { lang: language },
  }));

  return { paths, fallback: "blocking" };
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  try {
    const locale: string | null = (params?.lang as string) || null;
    let data = locale && locale !== "favicon.ico" ? await getContentTranslations("home", locale) : null;

    if (!data) {
      console.log(`Error: Content for "home" page and ${locale} localization not found`);
      data = await getContentByPath("home");
    }

    return { props: { ...data } };
  } catch (error) {
    return { notFound: true };
  }
};

export default Home;
